.navbar {
  width: 100%;
  position: relative;
}

.navbar-container {
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 0px;
  display: flex;
  justify-content: center; 
  align-items: center;
  height: 64px;
  position: relative; 
}

.navbar-logo {
  position: absolute;
  left: 50%;
  transform: translateX(-50%); 
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
}

.navbar-logo .logo-link {
  text-decoration: none;
  color: #1eb3d8;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.navbar-logo h1 {
  font-size: 3rem;
  font-weight: bold;
  margin: 0;
  text-align: center;
}

.navbar-logo p {
  font-size: 2.0rem;
  margin: 0;
  text-align: center;
  white-space: nowrap;
  display: inline-block; 
}

.menu-toggle {
  background: #007BFF;
  border: none;
  font-size: 1.5rem;
  cursor: pointer;
  color: #333333;
  position: absolute;
  right: 20px; /* Positions the toggle on the far right */
  top: 50%;
  transform: translateY(-50%);
}

.navbar-container {
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 64px;
}

.navbar-logo .logo-link {
  text-decoration: none;
  color: #1eb3d8;
  margin: auto;
  margin-right: 50%;
}

.navbar-logo h1 {
  font-size: 1.5rem;
  font-weight: bold;
  margin: 0;
 
}

.navbar-logo p {
  font-size: 0.8rem;
  margin: 0;
  text-align: center;
 
}

.navbar-links {
  display: flex;
  align-items: center;
  
}

.nav-link {
  color: #333333;
  text-decoration: none;
  padding: 10px 15px;
  margin-left: 10px;
  border-radius: 4px;
  transition: background-color 0.3s;
}

.nav-link:hover {
  background-color: #1eb3d8;
  color: #ffffff;
}



/* Sidebar Styles */
.sidebar {
  position: fixed;
  top: 0;
  right: 0;
  width: 90%; 
  max-width: 600px; 
  height: 100%;
  background-color: #ffffff;
  box-shadow: -2px 0 5px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease;
  padding: 35px;
  overflow-y: auto;
  z-index: 1000;
  transform: translateX(100%);
}


.sidebar.open {
  transform: translateX(0);
}

.sidebar-content {
  display: flex;
  flex-direction: column;
}

.close-button {
  align-self: flex-end;
  background: none;
  border: none;
  font-size: 1.5rem;
  cursor: pointer;
  color: #333333;
  margin-bottom: 20px;
}

.sidebar-links {
  display: flex;
  flex-direction: column;
 
}

.sidebar-links .nav-link {
  margin: 5px 0;
  padding: 10px 15px;
  margin-top: 10px;
  background-color: #f0f0f0;
  border-radius: 10px;
}

.sidebar-links .nav-link:hover {
  background-color: #1eb3d8;
  color: #ffffff;
}


.sidebar-button {
  width: 100%;
  padding: 10px;
  background-color: #da450a;
  color: white;
  border: none;
  border-radius: 8px;
  cursor: pointer;
  margin: 0 auto; /* Centering the button */
}


.sidebar-button:hover {
  background-color: #e90a0a;
}


.logout-section {
  display: flex;
  justify-content: center; /* Center the logout button */
}





.divider {
  border-top: 1px solid #cccccc;
  margin: 22px;
}



/* Responsive adjustments */
@media (max-width: 768px) {
  .navbar-links .nav-link {
    display: none;
  }

  .menu-toggle {
    display: block;
    background-color: #007BFF;
    
  }
}

@media (min-width: 769px) {
  .sidebar {
    width: 40%; /* Smaller width for larger screens */
  }
}


.home-container {
  display: flex;
  justify-content: center;
  align-items: flex-start;
  min-height: 100vh;
  background-color: #f5f5f5;
  padding: 5px;
  flex-direction:column;
  max-width: 100%;
}

.home-init-form {
  background-color: white;
  padding: 5px;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  width: 100%;
  flex: 1;
}

/* Chat Request label and input */
.problem-init {
  font-weight: bold;
  font-size: 1.2rem;
  margin-bottom: 15px;
  display: block;
  width: 100%;
}

#problem-init {
  width: 100%;
  padding: 10px;
  margin-bottom: 20px;
  border: 1px solid #ccc;
  border-radius: 20px;
  font-size: 1rem;
  outline: none;
  transition: border-color 0.3s ease;
  box-sizing: border-box;
}

#problem-init:focus {
  border-color: #007bff;
}
.input-container {
  display: flex;
  flex-direction: column;
  width: 100%;
  width: auto;
}

.problem-textarea {
  width: 100%;
  min-height: 100px;
  padding: 10px;
  border: 1px solid #ccc;
  border-bottom: none;
  border-radius: 4px 4px 0 0;
  resize: vertical;
  box-sizing: content-box;
}

.file-upload-container {
  width: 100%;
  padding: 10px;
  background-color: #f8f8f8;
  border: 1px solid #ccc;
  border-top: none;
  border-radius: 0 0 4px 4px;
  box-sizing: border-box;
}

.file-input {
  font-size: 14px;
 
}


.assistant-toggle{
  padding: 7px;
  margin-bottom: 10px;
  color: white;
  font-weight: bold;
  background-color: #64afff;
  box-sizing:border-box;
  max-width: fit-content;
  border-radius: 15px;

}

.submit-to-assistant{

  background-color: #218838;
  color: white;
  padding: 10px;
  font-size: 1rem;
  border: none;
  border-radius: 15px;
  cursor: pointer;
  width: 100%;
  transition: background-color 0.3s ease;
  margin-bottom: 10px;

}

.submit-to-assistant:hover{
  background-color: #0f963c;
  cursor: pointer;
  
}

.assistant-breakdown-content{
  width: 100%;
  border: 1px solid #ccc;
  border-radius: 20px;
  font-size: 1rem;
  margin-bottom: 20px;
  resize: vertical;
  box-sizing: border-box;
  padding-inline: 10px;
  margin-top: 15px;

}

.assistant-breakdown-content:focus{
  border-color: #007bff;

}

.assistant-breakdown-label{
  margin-bottom: 30px;
  border: 1px solid #ccc;
  border-radius: 20px;
  font-size: 1.2rem;
  border: none;
  font-weight: bold;
  outline: none;
  transition: border-color 0.3s ease;
  margin-bottom: 20px;
}

.assistant-breakdown-content {
  padding: 10px;
  border: 1px solid #ccc;
  background-color: #f9f9f9;
  max-height: 400px;
  overflow-y: auto;
  font-family: Arial, sans-serif;
  border-radius: 15px;
}

.assistant-breakdown-content h1, h2, h3 {
  color: #333;
  margin-bottom: 10px;
}

.assistant-breakdown-content p {
  margin-bottom: 10px;
}

.assistant-breakdown-content ul {
  margin-left: 20px;
}
.footer {
  background-color: #f8f9fa;
  text-align: center;
  padding: 20px;
  font-size: 14px;
  color: #6c757d;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%; 
  max-width: 100%; 
  margin-left: 0;
  margin-right: 0; 
  box-sizing: border-box; 
}


.footer p {
  margin: 5px 0;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
}

.footer a {
  color: #007bff;
  text-decoration: none;
  margin: 0 10px;
  white-space: nowrap;
}

.footer a:hover {
  text-decoration: underline;
}

/* Responsive behavior: stack links neatly on smaller screens */
@media (max-width: 768px) {
  .footer p {
    flex-direction: column;
    align-items: center;
  }
  
  .footer a {
    margin: 5px 0;
  }
}


.chat-response textarea {
  width: 100%;
  min-height: 100px;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
  font-size: 1rem;
  margin-bottom: 15px;
  resize: vertical;
  box-sizing: border-box;
}




.choose-role {
  font-weight: bold;
  font-size: 1.2rem;
  margin-bottom: 15px;
  display: block;
}

/* Flexbox layout for dropdowns */
.assignment-list {
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
  padding: 0;
  list-style-type: none;
}

.assignment-list li {
  flex: 1;
  margin-right: 20px;
}

.assignment-list li:last-child {
  margin-right: 0;
}

/* Dropdown styling */
.roles-dropdown {
  position: relative;
  width: 100%;
}

.dropbtn {
  background-color: #1eb3d8;
  color: white;
  font-weight:600;
  padding: 10px;
  font-size: 1rem;
  border: none;
  border-radius: 15px;
  cursor: pointer;
  text-align: left;
  outline: none;
  transition: background-color 0.3s ease;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;


  &:hover.dropdown-content {
    display: block;
  }
}

.dropbtn:hover {
  background-color: #0056b3;
}
/* Button hover effect */
.dropbtn:hover {
  background-color: #45a049; 
}

/* Dropdown menu styling */
.dropdown-menu {
  display: block;
  position: absolute; 
  background-color: white; 
  min-width: 160px; 
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2); 
  z-index: 1; 
  border-radius: 15px; 
  margin-top: 5px; 
  overflow: hidden; 
}

/* Dropdown menu item styling */
.dropdown-menu div {
  padding: 12px 16px; /* Padding for items */
  cursor: pointer; /* Pointer cursor on hover */
  transition: background-color 0.2s ease; /* Smooth transition */
}

/* Dropdown menu item hover effect */
.dropdown-menu div:hover {
  background-color: #76daf3; 
}
/* Container for role input */
.role-input {
  margin: 10px 0; 
  padding: 15px;
  background-color: #e3ebf3;
  border-radius: 15px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  display: flex; 
  flex-direction: column;
  width: 100%; 
  box-sizing: border-box; 
  
}


/* Label styling */
.model-instructions {
  font-size: 0.9rem;
  color: black;
  margin-bottom: 10px;
  font-weight: bold;
  
}

/* Input field styling */
.role-input-field {
  box-sizing: border-box;
  padding: 10px;
  font-size: 16px;
  border: 1px solid #ccc;
  border-radius: 15px;
  outline: none;
  box-shadow: inset 0 1px 2px rgba(0, 0, 0, 0.1);
  transition: border-color 0.3s ease, box-shadow 0.3s ease;
  width: 100%; 
}

/* Input field focus styling */
.role-input-field:focus {
  border-color: #4CAF50; /* Green border on focus */
  box-shadow: 0 0 5px rgba(76, 175, 80, 0.2); /* Green glow effect on focus */
}

/* Add responsiveness */
@media (max-width: 600px) {
  .role-input {
    margin: 8px 0; 
  }

  .role-input-field {
    font-size: 14px; 
  }
}


/* Submit button */
.submit-form {
  background-color: #218838;
  color: white;
  padding: 10px;
  font-size: 1rem;
  border: none;
  border-radius: 15px;
  cursor: pointer;
  width: 100%;
  transition: background-color 0.3s ease;
  margin-top: 20px;
  margin-bottom: 0%;
}

.submit-form:hover {
  background-color: #07fd59;
}
@media screen and (max-width: 768px) {
  /* Navbar for small screens */
  .navbar-container {
    flex-direction: column;
    align-items: flex-start;
    max-width: 1200px;
    margin: 0 auto;
    padding: 5 20px;
    display: flex;
    justify-content: center; /* Changed to center */
    background-color: white;
    align-items: center;
    height: 64px;
    position: relative; /* Added for absolute positioning context */
  }

  .navbar-logo h1 {
    font-size: 18px;
  }

  .navbar-logo p {
    font-size: 10px;
  }

  .navbar-links {
    background-color: #006bbf;
  }

  .menu-toggle {
    display: block;
    background: #007BFF;
    border: none;
    font-size: 30px;
    color: white;
    cursor: pointer;
  }

  .sidebar {
    width: 200px; /* Sidebar smaller for mobile */
  }

  .sidebar-content {
    padding: 15px;
  }

  .sidebar-links {
    gap: 10px;
  }

  .sidebar-button {
    padding: 8px;
  }

  .card {
    padding: 10px;
  }

  .delete-button {
    padding: 4px;
  }

  /* When sidebar is open */
  .sidebar.open {
    transform: translateX(0);
  }
}

@media screen and (max-width: 480px) {
  /* Adjust further for very small devices */
  .navbar-logo h1 {
    font-size: 16px;
  }

  .navbar-logo p {
    font-size: 8px;
  }

  .sidebar {
    width: 180px;
  }

  .sidebar-content {
    padding: 10px;
  }

  .sidebar-links {
    gap: 8px;
  }

  .sidebar-button {
    padding: 6px;
  }

  .card {
    padding: 8px;
  }

  .delete-button {
    padding: 3px;
  }
}

/* Mobile responsive */
@media (max-width: 768px) {
  .assignment-list {
    flex-direction: column;
  }

  .assignment-list li {
    margin-right: 0;
    margin-bottom: 20px;
  }

  .assignment-list li:last-child {
    margin-bottom: 0;
  }

  .submit-form {
    font-size: 0.9rem;
  }
}



/* Chat Page Container */
.chat-page {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

/* Problem Statement Container */
.problem-statement {
  background-color: #f8f9fa; /* Light background color */
  border: 1px solid #e0e0e0; /* Border for distinction */
  border-radius: 15px; /* Rounded corners */
  padding: 15px; /* Padding inside the problem statement */
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); /* Subtle shadow for depth */
  margin-bottom: 10px;
  margin-top: 10px; /* Space below the problem statement */
}

/* Problem Statement Text */
.problem-statement p {
  text-align: center;
  margin: 0; /* Remove default margin */
  font-size: 1.2rem; /* Slightly larger font size for emphasis */
  color: #333; /* Darker text color for readability */
}

.scrollable-models-container {
  width: 100%;
  overflow-x: auto; /* Enable horizontal scrolling */
  -webkit-overflow-scrolling: touch; /* Smooth scrolling on iOS */
}


/* Container for Model Responses */
.models-container {
  display: flex;
  flex-wrap: nowrap; /* Prevent wrapping onto new lines */
  gap: 20px; /* Space between items */
  border-radius: 15px;
}


/* Chat Response Styles */
.chat-response {
  background-color: #ffffff;
  border: 1px solid #e0e0e0;
  border-radius: 15px;
  padding: 10px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  position: relative;
  transition: max-height 0.3s ease;
  overflow: hidden;
  flex: 1 1 calc(33.333% - 20px); /* Default to 1/3 width */
  max-width: calc(33.333% - 20px); /* Prevent exceeding 1/3 width */
}



.chat-response.hidden {
  display: none; /* Hide the response when hidden */
}

/* Header for Chat Response */
.chat-response-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

/* Copy Button */
.copy-button {
  background-color: #007bff;
  color: white;
  border: none;
  padding: 5px 10px;
  border-radius: 10px;
  cursor: pointer;
  padding: '6px 12px';
  border: 'none';
  
}

.copy-button:hover {
  background-color: #0056b3;
}

/* Expand/Hide Buttons */
.chat-response-buttons button {
  margin-left: 10px;
  padding: 5px 10px;
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 10px;
  cursor: pointer;
}

.chat-response-buttons button:hover {
  background-color: #0056b3;
}

/* Form and Text Area Styles */
.chat-response p {
  font-style: italic;
  color: #666;
  margin-bottom: 15px;
}

.chat-response textarea {
  width: 100%;
  min-height: 100px;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 15px;
  font-size: 1rem;
  margin-bottom: 15px;
  resize: vertical;
  box-sizing: border-box;
  scroll-behavior: smooth;
  scrollbar-color: rgba(4, 67, 87, 0.133);
}

/* Model Feedback */
.model-feedback {
  display: flex;
  align-items: center;
  gap: 10px;
  
}

.response-area {
  margin-top: 12px;
  max-height: 400px; /* Adjust based on your layout */
  overflow-y: auto; /* Allow scrolling if content overflows */
}

.response-item {
  
  max-height: 500px; 
  overflow-y: auto; 
  border: 1px solid #ccc;
  padding: 16px;
  border-radius: 4px;
  padding: 10px;
  margin-bottom: 10px;
  border: 2px solid #ddd;
  border-radius: 10px;
  background-color: #f9f9f9; 
  white-space: pre-wrap; 
}

.response-item p {
  margin: 0;
}

/* Additional formatting styles */
.response-item strong {
  font-weight: bold;
}

.response-item em {
  font-style: italic;
}

.response-item a {
  color: #007bff;
  text-decoration: none;
}

.response-item a:hover {
  text-decoration: underline;
}

.response-item ul {
  margin: 0;
  padding-left: 20px; /* Indent lists */
}

.response-item li {
  margin-bottom: 5px; /* Space between list items */
}


.model-feedback-input {
  flex-grow: 1;
  padding: 8px;
  border: 1px solid #ccc;
  border-radius: 15px;
  font-size: 0.9rem;
  border: none;
  max-height: 20px;
  margin: auto;
}

.model-feedback-input:focus{
  border-color: #4CAF50; /* Green border on focus */
  box-shadow: 0 0 5px rgba(76, 175, 80, 0.2); /* Green glow effect on focus */

}

.submit-feedback {
  background-color: #007bff;
  color: white;
  border: none;
  padding: 8px 15px;
  border-radius: 10px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.submit-feedback:hover {
  background-color: #0056b3;
}



.hidden-response-item {
  margin-bottom: 10px;
}

.hidden-response-item button {
  background-color: #007bff;
  color: white;
  border: none;
  padding: 5px 10px;
  border-radius: 4px;
  cursor: pointer;
}

.hidden-response-item button:hover {
  background-color: #0056b3;
}

/* Responsive Design */
@media (max-width: 768px) {
  .scrollable-models-container .models-container .chat-response {
    min-width: 70vw;     
    margin-right: 10px; 
  }
  .chat-response {
    flex: 1 1 100%;
    max-width: 100%; 
    margin-bottom: 20px;
  }

  .chat-response textarea {
    min-height: 150px; 
  }

  .copy-button,
  .chat-response-buttons button,
  .submit-feedback,
  .hidden-response-item button {
    padding: 8px 15px; /* Slightly larger buttons for touch */
    font-size: 0.9rem;
  }

  .model-feedback-input {
    font-size: 0.9rem; /* Adjust font size */
  }
  .response-item{
    overflow-x: auto;
  }
}


/* Chat container for the page layout */
.chat-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 10vh;
  background-color: #f5f5f5;
}

/* Chat window that holds all messages and input field */
.chat-window {
  width: 100%;
  max-width: 800px;
  height: 80%;
  display: flex;
  flex-direction: column;
  background-color: white;
  border-radius: 10px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
  overflow: hidden;
}

/* Messages section that displays chat history */
.messages {
  flex-grow: 1;
  padding: 20px;
  overflow-y: auto;
  background-color: #e9e9e9;
}

/* Each message block */
.message {
  margin-bottom: 15px;
  padding: 10px;
  border-radius: 10px;
  max-width: 70%;
  line-height: 1.4;
  word-wrap: break-word;
}

/* User's messages */
.user-message {
  align-self: flex-end;
  background-color: #0084ff;
  color: white;
}

/* Model's messages */
.model-message {
  align-self: flex-start;
  background-color: red;
  color: black;
}



/* Input section at the bottom */
.input-container {
  display: flex;
  padding: 10px;
  background-color: #ffffff;
  border-top: 1px solid #ddd;
}

/* Input field for the user to type messages */
.input-field {
  flex-grow: 1;
  padding: 10px;
  border: 1px solid #ddd;
  border-radius: 20px;
  outline: none;
  font-size: 16px;
}

/* Send button for submitting the message */
.send-button {
  padding: 10px 20px;
  margin-left: 10px;
  background-color: #0084ff;
  border: none;
  border-radius: 20px;
  color: white;
  cursor: pointer;
  font-size: 16px;
}

.send-button:hover {
  background-color: #006bbf;
}


/* Single ChatPage Layout */
.chat-container {
  display: flex;
  justify-content: center;
  align-items: flex-start;
  max-height: max-content; 
  height: 105vh; 
  background-color: #f4f4f4;
  width: 100%;
  overflow-y: auto; 
  border-radius: 10px;
}

/* Chat window that holds all messages and input field */
.chat-window {
  width: 70%;
  max-width: 1000px;
  max-height: 100%; 
  background-color: white;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: column;
  overflow: hidden; 
}

.messages {
  flex-grow: 1;
  padding: 20px;
  overflow-y: auto;
  background-color: #e9e9e9;
  display: flex;
  flex-direction: column;
  gap: 15px;
}

/* Base message styles */
.message {
  margin-bottom: 15px;
  padding: 12px 20px;
  border-radius: 10px;
  max-width: 70%;
  line-height: 1.5;
  word-wrap: break-word;
  overflow-wrap: break-word;
  hyphens: auto;
}

/* User message specific styles */
.message.user {
  align-self: flex-end;
  background-color: #3c4a5c;
  color: #FAFAFA;
  margin-left: 30%;
}

/* Model message specific styles */
.message.model {
  align-self: flex-start;
  background-color: #DFF1E1;
  color: black;
  margin-right: 30%;
}

/* Responsive adjustments */
@media (max-width: 768px) {
  .message {
    max-width: 85%;
  }
  
  .message.user {
    margin-left: 15%;
  }
  
  .message.model {
    margin-right: 15%;
  }
}

/* Input section at the bottom */
.input-container {
  display: flex;
  padding: 10px;
  background-color: #ffffff;
  border-top: 1px solid #ddd;
}

/* Input field for the user to type messages */
.input-field {
  flex-grow: 1;
  padding: 10px;
  border: 1px solid #ddd;
  border-radius: 20px;
  outline: none;
  font-size: 16px;
}

/* Send button for submitting the message */
.send-button {
  padding: 10px 20px;
  margin-left: 10px;
  background-color: #0084ff;
  border: none;
  border-radius: 20px;
  color: white;
  cursor: pointer;
  font-size: 16px;
}

.send-button:hover {
  background-color: #006bbf;
}


.prose{
  font-size: 1rem;
}


/* Left-hand side cards styling */
.left-cards {
  width: 20%;
  display: flex;
  flex-direction: column;
  gap: 10px; 
}


.card {
  background-color: #f0f0f0;
  padding: 20px;
  text-align: center;
  height: 40px;
  border-radius: 8px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
}


.models-list {
  width: 250px; 
  padding: 16px;
  border-right: 1px solid #ddd; 
  overflow-y: auto; 
}


.model-card {
  cursor: pointer;
  border: 1px solid #ddd;
  border-radius: 8px;
  padding: 12px;
  text-align: center;
  margin: 8px 0;
  background-color: #f9f9f9;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  transition: transform 0.1s ease, background-color 0.3s ease;
  transform: scale(1);
}


.model-role{
  text-wrap: wrap;
}


.model-card:hover {
  background-color: #DFF1E1;  
  transform: scale(1.03);

}


.model-card.selected {
  background-color: #007bff;
  color: white;
  transform: scale(1.05);
}



/* Left section for the image or slideshow */
.image-section {
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #f4f4f4; /* Light background for contrast */
}


/* Login form container */
.login {
  width: 100%;
  max-width: 400px;
  background-color: #fff;
  padding: 30px;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: column;
  align-items: center;
}


/* Header styling */
.header {
  margin-bottom: 20px;
  font-size: 28px;
  text-align: center;
  color: #333;
}


/* Error message styling */
.error {
  color: #d9534f;
  font-size: 14px;
  margin-bottom: 10px;
  text-align: center;
}

/* Loading message styling */
.loading {
  color: #007bff;
  font-size: 14px;
  margin-bottom: 10px;
  text-align: center;
}


/* Login form styling */
.login-page {
  display: flex;
  justify-content: center;
  align-items: stretch;
  min-height: 100vh;
  background-color: #f9f9f9;
  padding: 0;
}


/* Right section for login form */
.login-container {
  flex: 1;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 20px;
  box-sizing: border-box;
  background-color: #fff;
  box-shadow: -5px 0 10px rgba(0, 0, 0, 0.1); /* Adds shadow similar to signup */
}





/* Login form container */
.login {
  width: 100%;
  max-width: 400px; /* Limit maximum width of the login form */
  background: white; /* Ensure form stands out */
  padding: 2rem; /* Space inside the login form */
  border-radius: 8px; /* Rounded corners */
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
}

/* Header styling */
.header {
  margin-bottom: 20px;
  font-size: 24px;
  text-align: center;
  color: #333;
}

/* Error and loading messages */
.error,
.loading {
  font-size: 14px;
  margin-bottom: 10px;
  text-align: center;
}

.error {
  color: #d9534f;
}

.loading {
  color: #007bff;
}

/* Login form styling */
.login-form {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 20px;
}

/* Email input field styling */
.email-label {
  font-size: 16px;
  color: #555;
  margin-bottom: 5px;
}

.email-input {
  width: 100%;
  padding: 10px;
  font-size: 14px;
  border: 1px solid #ccc;
  border-radius: 5px;
  transition: border-color 0.3s;
}

.email-input:focus {
  border-color: #007bff;
  outline: none;
}

/* Password field styling */
.password-field {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 10px;
}

.password-label {
  font-size: 16px;
  color: #555;
  margin-bottom: 5px;
}

.password-input {
  flex: 1;
  padding: 10px;
  font-size: 14px;
  border: 1px solid #ccc;
  border-radius: 5px;
  transition: border-color 0.3s;
}

.password-input:focus {
  border-color: #007bff;
  outline: none;
}

.toggle-password-btn {
  padding: 10px;
  font-size: 14px;
  color: #007bff;
  background-color: transparent;
  border: none;
  cursor: pointer;
}

/* Submit button styling */
.submit-btn {
  width: 100%;
  padding: 10px;
  font-size: 16px;
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s;
}

.submit-btn:hover {
  background-color: #0056b3;
}

/* Link styling for alternative login options (similar to signup) */
.signup-options {
  text-align: center;
  margin-top: 20px;
}

.login-span {
  display: block;
  margin-bottom: 10px;
  font-size: 14px;
  color: #666;
}

/* Responsive styles for small devices */
@media (max-width: 768px) {
  .login-page {
      flex-direction: column;
  }

  .preview-image {
      display: none; /* Hide image on small screens */
  }

  .login-container {
      padding: 15px;
  }

  .login {
      width: 90%;
      max-width: none;
      padding: 20px 15px;
      box-shadow: none;
  }

  .header {
      font-size: 20px;
  }

  .login-form .email-input,
  .login-form .password-input {
      font-size: 13px;
      padding: 8px;
  }

  .submit-btn {
      font-size: 14px;
      padding: 8px;
  }

  .signup-options {
      margin-top: 15px;
  }
}

/* email signup page component */

.signup-container {
  flex: 1;
  max-width: auto;
  width: 100%;
  padding: 20px;
  box-sizing: border-box;
  display: flex;
  justify-content: center;
  align-items: stretch;
  background-color: #ffffff; 
  box-shadow: -5px 0 10px rgba(0, 0, 0, 0.1);
  max-height: 100vh; 
}


.signup-form {
  flex: 1;
  background: #fff;
  padding: 20px 30px;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  width: 100%;
  max-width: 400px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin: 0;
}

.signup-form h2 {
  margin-bottom: 20px;
  font-size: 24px;
  text-align: center;
  color: #333;
}

.form-group {
  margin-bottom: 15px;
}

.form-group label {
  display: block;
  margin-bottom: 5px;
  font-weight: bold;
  color: #555;
}

.form-group input {
  width: 100%;
  padding: 10px;
  font-size: 14px;
  border: 1px solid #ccc;
  border-radius: 5px;
  transition: border-color 0.3s;
  box-sizing: border-box;
}

.form-group input:focus {
  border-color: #007bff;
  outline: none;
}

.form-group-btn{
  width: 100%; /* Full width like the input */
  padding: 10px;
  font-size: 14px; /* Same font size as input for visual consistency */
  color: white;
  background-color: #007bff; /* Add a color for the button */
  border: none;
  height: auto;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s;
  box-sizing: border-box;
  display: inline-block; /* Matches inline layout */

}

.form-group-btn-google {
  height: auto;
  width: 100%; /* Full width like the input */
  padding: 10px;
  font-size: 14px; /* Same font size as input for visual consistency */
  color: white;
  /* background-color: #007bff;  */
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s;
  box-sizing: border-box;
  display: inline-block; /* Matches inline layout */
}


.form-group-btn-google :hover{
  background-color: #c3ddf5;
}



.error-msg {
  color: #d9534f;
  font-size: 14px;
  margin-bottom: 10px;
  text-align: center;
}

.success-msg {
  color: #5cb85c;
  font-size: 14px;
  margin-bottom: 10px;
  text-align: center;
}

.submit-btn {
  width: 100%;
  padding: 10px;
  font-size: 16px;
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s;
}

.submit-btn:hover {
  background-color: #0056b3;
}

.signup-form a {
  color: #007bff;
  text-decoration: none;
}

.signup-form a:hover {
  text-decoration: underline;
}

.signup-form p {
  margin-top: 15px;
  text-align: center;
  font-size: 14px;
  color: #666;
}

/* Responsive styles for small devices */
@media (max-width: 768px) {
  .signup-container {
      flex-direction: column;
  }

  .preview-image {
      display: none;
  }

  .signup-form {
      padding: 15px 20px;
      box-shadow: none;
      width: 90%;
  }

  .signup-form h2 {
      font-size: 20px;
  }

  .form-group input {
      font-size: 13px;
      padding: 8px;
  }

  .submit-btn {
      font-size: 14px;
      padding: 8px;
  }
}



.chat-history {
  margin-top: 0px;
  padding: 20px;
  font-family: Arial, sans-serif;
}

.chat-history h3 {
  font-size: 24px;
  font-weight: bold;
  margin-bottom: 20px;
  text-overflow: ellipsis;
  color: #333;
}

.card p {
  margin: 0;
  line-height: 1.5;
  text-overflow: ellipsis;
  max-width: 600px;
}

.card p:first-child {
  font-size: 16px;
  color: #333;
  margin-bottom: 8px;
}

.card p:last-child {
  font-size: 14px;
  color: #666;
}

.card p:last-child::before {
  content: "\1F551"; /* Clock emoji */
  margin-right: 5px;
}


.signup-container {
  display: flex;
  align-items: stretch; /* Ensures image and content are equal in height */
  justify-content: center;
  min-height: 100vh;
  background-color: #f9f9f9;
  padding: 0; /* No extra padding between sections */
  font-family: Arial, sans-serif;
}

.preview-image {
  width: 50%; /* Image takes up half the width */
  height: auto;
  max-height: 100vh;
  object-fit: cover; /* Ensures proportional scaling without distortion */
  border-radius: 10px 0 0 10px; /* Rounded corners on the left side */
}

.signup-card {
  flex: 1; /* Form occupies the remaining space */
  display: flex;
  flex-direction: column; /* Stacks heading and buttons vertically */
  justify-content: center; /* Centers content vertically within the available space */
  align-items: center; /* Centers content horizontally */
  background: #fff;
  padding: 30px;
  border-radius: 0 10px 10px 0; /* Rounded corners on the right side */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  text-align: center;
}

.signup-card h2 {
  margin-bottom: 20px;
  font-size: 24px;
  color: #333;
}

.signup-options {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 15px; /* Space between buttons and separator */
  width: 100%; /* Ensures buttons span the full width of the form */
}

.signup-btn {
  width: 100%; /* Full-width button */
  padding: 10px 20px;
  font-size: 16px;
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s;
}

.signup-btn:hover {
  background-color: #0056b3;
}

.separator {
  font-size: 14px;
  color: #666;
  margin: 10px 0;
}

/* Responsive styles for small devices */
@media (max-width: 768px) {
  .signup-container {
      flex-direction: column; /* Stacks the image and form vertically on small screens */
  }

  .preview-image {
      width: 100%; /* Image takes up full width */
      height: auto;
      border-radius: 10px; /* Full rounded corners */
      margin-bottom: 20px; /* Space between image and form */
  }

  .signup-card {
      width: 100%;
      padding: 20px;
      border-radius: 10px; /* Full rounded corners */
      box-shadow: none;
  }

  .signup-btn {
      font-size: 14px;
      padding: 8px;
  }

  .separator {
      font-size: 12px;
  }
}

/* Loading Spinner */
.loading-spinner-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 200px;
  color: #666;
}

.loading-spinner {
  width: 40px;
  height: 40px;
  border: 3px solid #f3f3f3;
  border-top: 3px solid #3498db;
  border-radius: 50%;
  animation: spin 1s linear infinite;
  margin-bottom: 10px;
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

/* Refresh Overlay */
.refresh-overlay {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(255, 255, 255, 0.8);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 10;
}

.messages-container {
  position: relative;
  transition: opacity 0.3s ease;
}

.messages-container.refreshing {
  opacity: 0.5;
}

/* Model Card Loading */
.model-card {
  position: relative;
  transition: opacity 0.3s ease;
}

.model-card.refreshing {
  opacity: 0.7;
}

.card-loading-indicator {
  position: absolute;
  top: 50%;
  right: 10px;
  transform: translateY(-50%);
  width: 16px;
  height: 16px;
  border: 2px solid #f3f3f3;
  border-top: 2px solid #3498db;
  border-radius: 50%;
  animation: spin 1s linear infinite;
}

/* Disabled state for input and button */
.input-field:disabled,
.send-button:disabled {
  opacity: 0.7;
  cursor: not-allowed;
}


.usage-page {
  width: 80%;
  margin: 0 auto;
}

.balance-section {
  text-align: center;
  margin-bottom: 20px;
}

/* General Page Styles */
.usage-page {
  display: flex;
  justify-content: center;
  align-items: flex-start;
  padding: 20px;
  background-color: #f4f7f9; /* Light background color */
  font-family: 'Arial', sans-serif;
}

/* Card Styles */
.usage-card {
  background-color: #fff; /* White background for card */
  border-radius: 8px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1); /* Soft shadow for depth */
  width: 100%;
  max-width: 800px; /* Max width to limit card size */
  margin: 20px;
  padding: 20px;
}

/* Card Header Styles */
.card-header {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-bottom: 20px;
}

.total-cost {
  font-size: 24px;
  font-weight: bold;
  color: #333; /* Dark color for contrast */
}

/* View Controls Styles */
.view-controls {
  display: flex;
  justify-content: space-between;
  width: 100%;
  margin-top: 10px;
}

.toggle-buttons {
  display: flex;
}

/* Button Styles */
.toggle-btn {
  background-color: #007bff; 
  color: white;
  border: none;
  border-radius: 4px;
  padding: 10px 15px;
  cursor: pointer;
  transition: background-color 0.3s ease, transform 0.2s ease;
  margin-right: 10px;
}

.toggle-btn.active {
  background-color: #0056b3; 
}

.toggle-btn:hover {
  background-color: #0056b3; 
  transform: scale(1.05); 
}

/* Model Selector Styles */
.model-selector {
  margin: 10px 0;
}

.model-select {
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
  width: 100%;
  font-size: 16px;
  transition: border-color 0.3s ease;
}

.model-select:hover,
.model-select:focus {
  border-color: #007bff; /* Border color change on hover */
}

/* Chart Container Styles */
.chart-container {
  margin-top: 20px;
}

/* Loading Styles */
.loading {
  font-size: 18px;
  text-align: center;
  color: #666; /* Gray color for loading text */
}

/* Responsive Styles */
@media (max-width: 600px) {
  .usage-card {
      padding: 15px; /* Adjust padding for small screens */
  }

  .toggle-btn {
      padding: 8px 12px; /* Smaller padding for buttons */
  }

  .model-select {
      font-size: 14px; /* Smaller font for dropdown */
  }
}


.card-container {
  display: flex;
  flex-direction: column;
  gap: 1rem; /* Space between cards */
}

.card {
  background-color: #fff; /* Card background */
  border-radius: 10px; /* Rounded corners */
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1); /* Subtle shadow */
  overflow: hidden; /* Ensure content does not overflow */
  transition: transform 0.2s ease, box-shadow 0.2s ease; /* Transition effects */
}

.card:hover {
  transform: translateY(-2px); /* Lift effect on hover */
  box-shadow: 0 6px 20px rgba(0, 0, 0, 0.15); /* Deeper shadow on hover */
}

.card-content {
  padding: 1rem; /* Padding for card content */
  cursor: pointer; /* Pointer cursor on hover */
}

.problem-statement-history {
  font-size: 1.1rem; /* Slightly larger font size */
  font-weight: bold; /* Bold text */
  margin: 0; /* Remove margin */
  color: #333; /* Text color */
  word-wrap:normal; /* Wrap long words to avoid overflow */
}

.last-message-time {
  font-size: 0.9rem; /* Smaller font size for time */
  color: #777; /* Lighter color for time */
  margin-top: 0.5rem; /* Space above */
}

.delete-button {
  background-color: #ff6b6b; /* Red background */
  color: white; /* White text */
  border: none; /* Remove default border */
  border-radius: 5px; /* Rounded corners */
  padding: 0.5rem 1rem; /* Padding for button */
  cursor: pointer; /* Pointer cursor */
  transition: background-color 0.3s ease; /* Transition effect for hover */
  margin: 1rem; /* Margin around button */
}

.delete-button:hover {
  background-color: #ff4d4d; /* Darker red on hover */
}

/* Responsive design */
@media (max-width: 600px) {
  .problem-statement-history {
    font-size: 1rem; /* Smaller font size on mobile */
  }
  
  .last-message-time {
    font-size: 0.8rem; /* Smaller font size on mobile */
  }
  
  .delete-button {
    padding: 0.4rem 0.8rem; /* Smaller button on mobile */
  }
}


.copy-button-single-page{
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 4px;
  padding: 10px 15px;
  cursor: pointer;
  transition: background-color 0.3s ease, transform 0.2s ease;
  margin-right: 10px;
}


.language-tag{
  background-color: #f3f3f3;
  color: #333;
  font-weight: bold;
  box-sizing: content-box;
  border-radius: 5px;
  margin-right: 5px;
  

}


.code-block-container{
  border-radius: 50px;
  margin-top: 15px;
  margin-bottom: 15px;
}


.code-block-header{
  margin-top: 15px;
  margin-bottom: 5px;
  border-radius: 40px;
}


/* ProfilePage.css */

.profile-page-container {
  display: flex;
  min-height: 100vh;
  background-color: #f4f4f4;
  font-family: 'Arial', sans-serif;
}

.profile-sidebar {
  width: 300px;
  background-color: #ffffff;
  box-shadow: 0 0 10px rgba(0,0,0,0.1);
  display: flex;
  flex-direction: column;
}

.profile-summary-card {
  padding: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  border-bottom: 1px solid #e0e0e0;
}

.profile-avatar {
  width: 100px;
  height: 100px;
  border-radius: 50%;
  background-color: #3498db;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 15px;
}

.avatar-placeholder {
  font-size: 36px;
  color: #ffffff;
}

.profile-username {
  font-size: 1.2em;
  font-weight: bold;
  margin-bottom: 5px;
}

.profile-email {
  color: #7f8c8d;
  margin-bottom: 20px;
}

.profile-actions {
  padding: 20px;
}

.profile-section {
  margin-bottom: 20px;
}

.profile-section h3 {
  color: #2c3e50;
  margin-bottom: 10px;
  font-size: 1.1em;
}

.profile-section ul {
  list-style-type: none;
  padding-left: 10px;
}

.profile-section li {
  margin-bottom: 8px;
}

.profile-section a {
  color: #3498db;
  text-decoration: none;
  transition: color 0.3s ease;
}

.profile-section a:hover {
  color: #2980b9;
}

.profile-main-content {
  flex-grow: 1;
  padding: 40px;
  background-color: #ffffff;
  box-shadow: 0 0 10px rgba(0,0,0,0.1);
}

.profile-main-content h1 {
  color: #2c3e50;
  margin-bottom: 30px;
}

.profile-details {
  background-color: #ecf0f1;
  padding: 20px;
  border-radius: 5px;
}

.profile-field {
  margin-bottom: 20px;
}

.profile-field label {
  font-weight: bold;
  display: block;
  margin-bottom: 5px;
  color: #34495e;
}

.profile-field p {
  background-color: #ffffff;
  padding: 10px;
  border-radius: 3px;
  border: 1px solid #bdc3c7;
}

.profile-error {
  color: #e74c3c;
  background-color: #fadbd8;
  padding: 10px;
  border-radius: 3px;
  border: 1px solid #e74c3c;
}

/* Responsive Design */
@media (max-width: 768px) {
  .profile-page-container {
      flex-direction: column;
  }

  .profile-sidebar {
      width: 100%;
      order: 1;
  }

  .profile-main-content {
      order: 2;
  }
}


/* New styles for the additional user profile form */
.additional-user-profile {
  margin-top: 30px;
  background-color: #ffffff;
  padding: 20px;
  border-radius: 5px;
  box-shadow: 0 2px 5px rgba(0,0,0,0.1);
}

.additional-user-profile label {
  display: block;
  margin-bottom: 10px;
  font-weight: bold;
  color: #2c3e50;
}

.additional-user-profile textarea {
  padding: 10px;
  padding-right: 20px;
  box-sizing: border-box;
  width: 100%;
  height: 100px;
  border: 1px solid #bdc3c7;
  border-radius: 3px;
  resize: vertical;
  font-family: 'Arial', sans-serif;
  font-size: 14px;
}

.additional-user-profile textarea:focus {
  outline: none;
  border-color: #3498db;
  box-shadow: 0 0 5px rgba(52, 152, 219, 0.5);
}

.additional-user-profile input[type="submit"] {
  margin-top: 15px;
  padding: 10px 20px;
  background-color: #3498db;
  color: #ffffff;
  border: none;
  border-radius: 3px;
  cursor: pointer;
  font-size: 16px;
  transition: background-color 0.3s ease;
  border-radius: 10px;
}



.additional-user-profile input[type="submit"]:hover {
  background-color: #25d468;
}

/* Responsive adjustments */
@media (max-width: 768px) {
  .additional-user-profile textarea {
      height: 100px;
  }
}


/* Edit Message Container */
.edit-message-container {
  display: inline-flex; /* Ensure container fits content */
  flex-direction: column; /* Stack textarea and buttons vertically */
  gap: 10px; /* Space between the textarea and buttons */
  margin-top: 10px;
  margin-bottom: 10px;
  width: 100%; /* Allow the container to take full width */
}

/* Textarea for Editing Message */
.edit-message-textarea {
  padding: 8px;
  border: 1px solid #ccc;
  border-radius: 5px;
  font-size: 14px;
  width: 100%; /* Full width for mobile responsiveness */
  max-width: 600px; /* Max width for the textarea */
  min-height: 40px; /* Minimum height */
  resize: none; /* Disable manual resizing */
  overflow: hidden; /* Hide scrollbars */
  box-shadow: inset 0 1px 3px rgba(0, 0, 0, 0.1);
  outline: none;
  transition: border-color 0.2s ease;
}

/* Button Container */
.edit-message-buttons {
  display: flex;
  gap: 10px; /* Space between buttons */
}


/* Adjusting width dynamically based on the content */
.edit-message-input:focus {
  border-color: #007BFF;
  height: auto; /* Adjust the height as content grows */
  overflow-y: hidden; /* Prevent vertical scrolling */
  transition: height 0.2s ease; /* Smooth transition for height changes */
}

/* Edit Buttons Container */
.edit-message-buttons {
  display: flex;
  gap: 5px; /* Space between the buttons */
}

/* Save and Cancel Buttons */
.edit-message-buttons button {
  padding: 6px 12px;
  border-radius: 5px;
  border: none;
  font-size: 14px;
  cursor: pointer;
  transition: background-color 0.2s ease;
}

.edit-message-buttons button:first-child {
  background-color: #28a745;
  color: white;
}

.edit-message-buttons button:first-child:hover {
  background-color: #218838;
}

.edit-message-buttons button:last-child {
  background-color: #dc3545;
  color: white;
}

.edit-message-buttons button:last-child:hover {
  background-color: #c82333;
}

/* Styling for the edit button that appears on messages */
.edit-button {
  background-color: transparent;
  border: none;
  color: #007bff;
  font-size: 12px;
  cursor: pointer;
  margin-left: 8px;
}

.edit-button:hover {
  color: #0056b3;
  text-decoration: underline;
}

  .auth-modal-backdrop {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .auth-modal {
    background: white;
    padding: 2rem;
    border-radius: 10px;
    width: 300px;
    text-align: center;
  }
  
  .auth-buttons {
    display: flex;
    justify-content: space-between;
    margin-top: 1rem;
  }
  
  .login-button,
  .register-button {
    padding: 0.5rem 1rem;
    cursor: pointer;
  }
  
  .close-modal {
    margin-top: 1rem;
    color: #ff0000;
    cursor: pointer;
  }
  .single-chat-container {
    display: flex;
    height: 90vh;
    overflow: hidden;
    background-color: #fff;
  }
  
  .single-sidebar {
    width: 250px;
    background-color: #f5f5f5;
    border-right: 1px solid #ddd;
    padding: 20px;
    height: 100%;
    overflow-y: auto;
    flex-shrink: 0;
  }
  
  .single-models-list {
    display: flex;
    flex-direction: column;
    gap: 10px;
  }
  
  .single-model-card {
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 8px;
    cursor: pointer;
  }
  
  .single-active {
    background-color: #e0e0e0;
  }
  
  .single-models-wrapper {
    flex: 1;
    display: flex;
    flex-direction: column;
    height: 100%;
    position: relative;
    background-color: #fff;
    min-width: 0; /* Ensures proper flex behavior */
  }
  
  .single-model-response-container {
    position: relative; /* Changed from absolute to relative */
    height: 100%;
    display: flex;
    flex-direction: column;
    background-color: #fff;
    overflow: hidden; /* Ensures content doesn't overflow */
  }
  
  .single-chat-content {
    flex: 1;
    overflow-y: auto;
    padding: 20px;
    padding-bottom: 80px;
    display: flex;
    flex-direction: column;
  }
  
  .single-input-wrapper {
    position: absolute;
    bottom: 0;
    left: 20px; /* Match padding of chat content */
    right: 20px; /* Match padding of chat content */
    background-color: #fff;
    padding: 20px;
    border-top: 1px solid #ddd;
    z-index: 10;
    box-shadow: 0 -4px 6px -1px rgba(0, 0, 0, 0.1);
  }
  
  .single-input-container {
    display: flex;
    align-items: center;
    gap: 10px;
  }
  
  .single-input-field {
    flex: 1;
    padding: 10px;
    border: 1px solid #ddd;
    border-radius: 8px;
    font-size: large;
  }
  
  .single-send-button {
    margin-left: 10px;
    padding: 10px;
    background-color: #007bff;
    color: #fff;
    border: none;
    border-radius: 8px;
    cursor: pointer;
    font-size: large;
  }

  .single-send-button:hover{
    background-color: #12f15c;
  }
  
  .single-error-banner {
    background-color: #f8d7da;
    color: #721c24;
    padding: 10px;
    margin-bottom: 10px;
    border-radius: 8px;
  }
  
  .single-error-message {
    color: red;
    margin-top: 10px;
  }
  
  .single-swipe-indicator {
    display: none;
    padding: 10px 0;
    background-color: #fff;
    border-top: 1px solid #ddd;
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 9;
  }
  
  @media (max-width: 768px) {
    .single-sidebar {
      display: none;
    }
  
    .single-swipe-indicator {
      display: flex;
      justify-content: center;
    }
  
    .single-dot {
      width: 10px;
      height: 10px;
      background-color: #ccc;
      border-radius: 50%;
      margin: 0 5px;
    }
  
    .single-dot.single-active {
      background-color: #007bff;
    }
  }

  .custom-image {
    max-width: 100%;
    border-radius: 0.5rem;
  }

.error-message {
  color: red;
  font-weight: bold;
  font-family: sans-serif;
}



/* Container for the subscription page */
.container {
  max-width: 800px;
  margin: 0 auto;
  padding: 20px;
  text-align: center;
}

/* Page heading */
.heading {
  font-size: 2rem;
  font-weight: bold;
  margin-bottom: 20px;
}

/* Billing options */
.billing-options {
  display: flex;
  justify-content: center;
  margin-bottom: 20px;
}

.radio-label {
  margin: 0 10px;
  font-size: 1rem;
}

.radio-input {
  margin-right: 5px;
}

/* Plan grid */
.plans-grid {
  display: flex;
  gap: 20px;
  justify-content: center;
  flex-wrap: wrap;
}

/* Individual plan card */
.plan-card {
  border: 1px solid #ddd;
  border-radius: 8px;
  padding: 20px;
  width: 200px;
  text-align: center;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  transition: transform 0.2s ease-in-out;
}

.plan-card:hover {
  transform: scale(1.05);
}

/* Popular plan styling */
.popular-plan {
  border-color: #4caf50;
  background-color: #f0fff0;
}

/* Plan title */
.plan-title {
  font-size: 1.5rem;
  margin-bottom: 10px;
}

/* Plan price */
.plan-price {
  font-size: 1.2rem;
  color: #333;
  margin-bottom: 15px;
}

/* Get Started button */
.get-started-button {
  padding: 10px 20px;
  background-color: #007bff;
  color: #fff;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.2s ease;
}

.get-started-button:disabled {
  background-color: #ccc;
  cursor: not-allowed;
}

.get-started-button:hover:not(:disabled) {
  background-color: #0056b3;
}

/* Error and success messages */
.error-message {
  color: #d9534f;
  margin-top: 15px;
  font-size: 1rem;
}

.success-message {
  color: #5cb85c;
  margin-top: 15px;
  font-size: 1rem;
}

/* PaymentPage.css */

.payment-page {
  text-align: center;
  max-width: 400px;
  margin: 0 auto;
}

.payment-page form {
  display: flex;
  flex-direction: column;
}

.payment-page label {
  margin-bottom: 10px;
  font-size: 16px;
}

.payment-page input {
  padding: 8px;
  font-size: 16px;
  margin-top: 4px;
}

.payment-page button {
  padding: 10px;
  font-size: 16px;
  margin-top: 20px;
  cursor: pointer;
}


/* SubscriptionPage.module.css */

.subscription-container {
  text-align: center;
  padding: 20px;
  font-family: Arial, sans-serif;
  border-radius: 20px;
  background-color: #fdfeff;
}

.plans-grid {
  display: flex;
  gap: 20px;
  justify-content: center;
  margin-top: 20px;
}

.plan-card {
  background-color: #f9f9f9;
    border: 1px solid #ddd;
    border-radius: 8px;
    padding: 20px;
    width: 250px;
    height: 350px; 
    display: flex;
    flex-direction: column;
    justify-content: space-between; 
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    transition: transform 0.3s ease;
}

.plan-card:hover {
  transform: translateY(-5px);
}

.plan-title {
  font-size: 1.5em;
  margin-bottom: 10px;
  color: #333;
}

.plan-price {
  font-size: 1.2em;
  margin-bottom: 15px;
  color: #666;
}

.plan-description-list {
  list-style-type: none;
  padding: 0;
  margin: 10px 0;
}

.plan-description-item {
  display: flex;
  align-items: center;
  font-size: 1em;
  padding: 5px 0;
  color: #555;
}

.plan-description-item::before {
  content: '✓';
  color: #4CAF50;
  font-weight: bold;
  margin-right: 8px;
}

.get-started-button {
  margin-top: 15px;
  padding: 10px 20px;
  font-size: 1em;
  color: #fff;
  background-color: #4CAF50;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.get-started-button:hover {
  background-color: #45a049;
}

.popular-plan {
  border: 2px solid #4CAF50;
}



/* subscription management page */
.subscription-management {
  max-width: 700px;
  margin: 0 auto;
  padding: 24px;
}

.error-message {
  margin-bottom: 16px;
  padding: 16px;
  background-color: #ffe5e5;
  color: #b32a2a;
  border-radius: 8px;
}

.subscription-details,
.payment-history {
  background-color: #ffffff;
  border-radius: 8px;
  padding: 24px;
  margin-bottom: 32px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.section-title {
  font-size: 1.5em;
  font-weight: bold;
  margin-bottom: 16px;
}

.subscription-info {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 16px;
}

.label {
  color: #6b7280;
}

.value {
  font-weight: 500;
}

.cancel-button {
  margin-top: 24px;
  padding: 10px 16px;
  background-color: #b32a2a;
  color: #ffffff;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.2s;
}

.cancel-button:hover {
  background-color: #9f1f1f;
}

.table-container {
  overflow-x: auto;
}

.payment-table {
  width: 100%;
  border-collapse: collapse;
}

.payment-table th,
.payment-table td {
  padding: 12px;
  text-align: left;
}

.payment-table th {
  font-size: 0.875em;
  color: #6b7280;
  text-transform: uppercase;
  background-color: #f9fafb;
}

.payment-table td {
  border-top: 1px solid #e5e7eb;
}

.status-badge {
  padding: 4px 8px;
  border-radius: 9999px;
  font-size: 0.75em;
  text-transform: capitalize;
}

.status-badge.paid {
  background-color: #d1fae5;
  color: #065f46;
}

.status-badge.unpaid {
  background-color: #fef3c7;
  color: #92400e;
}

.invoice-link {
  color: #2563eb;
  text-decoration: none;
}

.invoice-link:hover {
  text-decoration: underline;
}

.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
}

.modal {
  background: #ffffff;
  padding: 24px;
  border-radius: 8px;
  max-width: 400px;
}

.modal-title {
  font-size: 1.25em;
  font-weight: bold;
  margin-bottom: 16px;
}

.modal-text {
  margin-bottom: 24px;
}

.modal-actions {
  display: flex;
  justify-content: flex-end;
  gap: 16px;
}

.keep-button {
  padding: 8px 16px;
  background-color: #e5e7eb;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.2s;
}

.keep-button:hover {
  background-color: #d1d5db;
}

.confirm-cancel-button {
  padding: 8px 16px;
  background-color: #b32a2a;
  color: #ffffff;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.2s;
}

.confirm-cancel-button:hover {
  background-color: #9f1f1f;
}

.plan-description {
  list-style-type: disc;
  padding-left: 20px;
  margin: 10px 0;
}

.terms-container {
  max-width: 700px;
  margin: 30px auto;
  padding: 30px;
  font-family: 'Georgia', 'Times New Roman', serif;
  background-color: #ffffff;
  border: 1px solid #e0e0e0;
  border-radius: 8px;
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.05);
  line-height: 1.7;
}

.terms-container h1 {
  font-size: 2rem;
  margin-bottom: 30px;
  color: #2c3e50;
  text-align: center;
  border-bottom: 2px solid #3498db;
  padding-bottom: 15px;
}

.terms-container .terms-text {
  color: #333;
  font-size: 1.1rem;
}

.terms-container .terms-text p {
  margin-bottom: 20px;
  text-indent: 30px;
  text-align: justify;
  hyphens: auto;
}

.terms-container .terms-text h3 {
  color: #2980b9;
  margin-top: 25px;
  margin-bottom: 15px;
  font-size: 1.4rem;
  border-bottom: 1px solid #ecf0f1;
  padding-bottom: 10px;
}



.callback-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100vh;
  background-color: #f0f2f5;
  text-align: center;
}

.loading-spinner {
  border: 4px solid #f3f3f3;
  border-top: 4px solid #3498db;
  border-radius: 50%;
  width: 50px;
  height: 50px;
  animation: spin 1s linear infinite;
  margin-bottom: 20px;
}

.callback-container p {
  color: #333;
  font-size: 18px;
}

.callback-container.error {
  background-color: #ffebee;
}

.callback-container.error p {
  color: #d32f2f;
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

.callback-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100vh;
  background-color: #f0f2f5;
  text-align: center;
}

.loading-spinner {
  border: 4px solid #f3f3f3;
  border-top: 4px solid #3498db;
  border-radius: 50%;
  width: 50px;
  height: 50px;
  animation: spin 1s linear infinite;
  margin-bottom: 20px;
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

